import Flex from 'components/Flex/Flex';
import { ProductCardSimple } from 'components/Product/ProductCard';
import SkeletonCard from 'components/Skeleton/Card/SkeletonCard';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS } from 'constants/generic';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import { useElementViewed } from 'hooks/useElementViewed';
import useProductCard from 'hooks/useProductCard';
import { ReactNode, Ref, memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { Product } from 'types/Product';
import { removeWhiteSpaces } from 'utils/textUtil';
import { StyledSlider, StyledSliderItemWrapper } from './ProductsSlider.styled';

export const DEFAULT_SLIDES_OBJECT: SlidesObject = {
  [BREAKPOINTS.XS]: 1.5,
  [BREAKPOINTS.SM]: 1.5,
  [BREAKPOINTS.MD]: 2.5,
  [BREAKPOINTS.LG]: 4,
  [BREAKPOINTS.XL]: 5,
  [BREAKPOINTS.XXL]: 5,
};

export interface ProductSliderProps {
  alwaysHideArrows?: boolean;
  alwaysShowArrows?: boolean;
  amount?: number;
  button?: ReactNode;
  className?: string;
  id?: string;
  ignoreSlideMaxWidth?: boolean;
  isLoading?: boolean;
  itemGap?: number;
  newTab?: boolean;
  onAddToCart?: (product: Product) => void;
  onView?: () => void;
  productSliderRef?: Ref<EmblaCarouselType>;
  products?: Product[];
  shouldClampSlidesToAvailable?: boolean;
  showAddToCart?: boolean;
  showAmount?: boolean;
  showCashBack?: boolean;
  showSignings?: boolean;
  sliderOptions?: EmblaOptionsType;
  slidesObject?: SlidesObject;
  subtitle?: string;
  title?: string;
  titleColor?: string;
}

const ProductSliderCard = ({
  newTab,
  onAddToCart,
  product,
  showAddToCart,
  showSignings,
}: {
  newTab?: boolean;
  onAddToCart?: (product: Product) => void;
  product: Product;
  showAddToCart?: boolean;
  showSignings?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { mapProductCardSimpleProps } = useProductCard();
  const { signing, ...props } = mapProductCardSimpleProps({
    product,
    ...(showAddToCart && {
      onAddToCart: async () => {
        try {
          setLoading(true);
          await onAddToCart?.(product);
        } finally {
          setLoading(false);
        }
      },
    }),
  });

  return (
    <ProductCardSimple
      {...props}
      newTab={newTab}
      loading={loading}
      data-swogo-sku={product?.code}
      signing={showSignings ? signing : undefined}
    />
  );
};

export const ProductsSliderComponent = ({
  alwaysHideArrows,
  alwaysShowArrows,
  amount,
  button,
  className,
  id,
  ignoreSlideMaxWidth,
  isLoading,
  itemGap,
  newTab,
  onAddToCart,
  onView,
  products,
  productSliderRef,
  shouldClampSlidesToAvailable,
  showAddToCart,
  showAmount,
  showSignings,
  sliderOptions,
  slidesObject = DEFAULT_SLIDES_OBJECT,
  subtitle,
  title,
  titleColor,
}: ProductSliderProps) => {
  const { formatMessage } = useIntl();

  const { ref } = useElementViewed(onView);

  const amountOfProducts = amount || products?.length;

  const additionalSubtitle =
    showAmount &&
    formatMessage(
      {
        id: amountOfProducts === 1 ? 'single_product' : 'total_amount_of_products',
      },
      { count: amountOfProducts },
    );

  const isServerRendered = typeof window === 'undefined';

  const showArrows = alwaysHideArrows
    ? {
        [BREAKPOINTS.XS]: false,
      }
    : {
        [BREAKPOINTS.MD]: true,
        ...(!isServerRendered && alwaysShowArrows && { [BREAKPOINTS.XS]: true }),
      };

  const options: EmblaOptionsType = { dragFree: true, slidesToScroll: 'auto', ...sliderOptions };

  return (
    <div id={id} ref={ref} className={className}>
      <StyledSlider
        ref={productSliderRef}
        slidesToShow={slidesObject}
        subtitle={removeWhiteSpaces(`${subtitle || ''} ${additionalSubtitle || ''}`)}
        title={
          title || button ? (
            <Flex alignItems="center" flexWrap="wrap" gap={50}>
              {title} {button}
            </Flex>
          ) : undefined
        }
        titleColor={titleColor}
        showArrows={showArrows}
        options={options}
        itemGap={itemGap}
        arrowPosition={ARROW_POSITIONS.CENTER}
        ignoreSlideMaxWidth={ignoreSlideMaxWidth}
        shouldClampSlidesToAvailable={shouldClampSlidesToAvailable}
      >
        {isLoading &&
          [...Array(10)].map((_, index) => (
            <StyledSliderItemWrapper key={`${title || subtitle}-${index}`}>
              <SkeletonCard />
            </StyledSliderItemWrapper>
          ))}
        {!isLoading &&
          products?.map((product) => (
            <ProductSliderCard
              showSignings={showSignings}
              showAddToCart={showAddToCart}
              onAddToCart={onAddToCart}
              product={product}
              newTab={newTab}
              key={`ProductSliderCard-${product?.code}`}
            />
          ))}
      </StyledSlider>
    </div>
  );
};

export default memo(ProductsSliderComponent, isEqual);
